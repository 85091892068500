import { captureException } from '@sentry/node';

export const gtrack = (d1?: any, d2?: any, d3?: any) => {
  if (window.gtag != null) {
    try {
      window.gtag(d1, d2, d3);
    } catch (e) {
      captureException(e);
    }
  }
};

export const trackPageview = () => {
  gtrack('config', 'AW-784813530', {
    page_location: window.location.href,
    page_path: window.location.pathname,
  });
  gtrack('set', 'page', window.location.pathname);
  gtrack('send', 'pageview');
};

export const trackEvent = (
  action: string,
  category?: string,
  label?: string,
  value?: number,
  extra?: any
) => {
  gtrack('event', action, { event_category: category, event_label: label, value, ...extra });
};

export const trackLogin = (method?: string) => {
  trackEvent('login', 'engagement', undefined, undefined, { method });
};

export const trackSearch = (searchTerm: string) => {
  trackEvent('search', 'engagement', undefined, undefined, { search_term: searchTerm });
};

export const trackShare = (method?: string) => {
  trackEvent('share', 'engagement', undefined, undefined, { method });
};

export const trackViewSearch = (searchTerm: string) => {
  trackEvent('view_search_results', 'engagement', undefined, undefined, {
    search_term: searchTerm,
  });
};

export const trackLead = () => {
  trackEvent('generate_lead', 'engagement');
  gtrack('event', 'conversion', { send_to: 'AW-784813530/dFwlCN-kmOwCENqbnfYC' });
};

export const trackSignUp = (method?: string) => {
  trackEvent('sign_up', 'engagement', undefined, undefined, { method });
};

export const trackDeviceID = (userId?: string) => {
  gtrack('config', 'AW-784813530', { user_id: userId });
};

export const trackViewItemList = (label?: string) => {
  trackEvent('view_item_list', 'engagement', label);
};

export const trackViewItem = (label?: string) => {
  trackEvent('view_item_list', 'engagement', label);
};

export const trackRealty = (id?: string) => {
  trackViewItem(`Realty:${id}`);
};

export const trackRealtyListing = (path: string) => {
  trackViewItemList(`Listing:${path}`);
};
