import * as React from 'react';
import { OptimizedImage } from '@app/components/optimized-image';
import { Layout } from '@app/components/layout';
import {
  Typography,
  Container,
  Button,
  Grid,
  List,
  ListItem,
  Collapse,
  Box,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { WithTranslation } from 'next-i18next';
import { i18n } from '../i18n';
import '@app/styles/home.scss';
import { withApollo, NextApolloPage } from '@app/util/apollo';
import { SelectField } from '@app/components/form/select-field';
import { useRouter } from 'next/router';
import { RealestateCategory } from '@app/components/realestate/realestate-category';
import { RealestateSearch } from '@app/components/realestate/realestate-search';
import { useLocation } from '../src/lib/hooks/use-location';
import {
  SearchTopResultsQuery,
  SearchTopResultsQueryVariables,
  SearchTopResultsDocument,
} from '@app/components/graphql-hooks';
import { RealestateTopSearchLink } from '@app/components/realestate/realestate-top-search-link';
import { trackSearch } from '@app/util/pixel-event';
import { trackSearch as trackGSearch } from '@app/util/google-event';
import { decodeId } from '@app/util/relay';
import { searchRealtyUrls } from '@app/util/realty-listing';
import { announceButtonHref } from '@app/lib/constants/announce-url';

interface ApolloProps {
  namespacesRequired: string[];
  searchTop: SearchTopResultsQuery | undefined;
}

type Props = ApolloProps & WithTranslation;

const Home: NextApolloPage<Props, ApolloProps> = props => {
  const { t } = props;
  const { latitude, longitude } = useLocation();
  const router = useRouter();
  const [collapseTopSearchsOpen, setCollapseTopSearchsOpen] = React.useState(false);

  const searchRealestate = (values: any) => {
    if (values.search && !values.search?.city?.state?.slug) {
      return;
    }
    const { url, asPath } = searchRealtyUrls({
      t,
      filter: {
        isForRent: values?.type === 'rent' ? true : undefined,
        isForSale: values?.type === 'sale' ? true : undefined,
        category: values?.search?.venture != null ? undefined : values?.realestateType,
        state: values?.search?.venture != null ? undefined : values?.search?.city?.state?.code,
        city: values?.search?.venture != null ? undefined : values?.search?.city?.slug,
        districtList: values?.search?.venture != null ? undefined : values?.search?.districtSlug,
        street: values?.search?.venture != null ? undefined : values?.search?.street,
      },
      partnership: false,
      includePage: false,
      venture:
        values?.search?.venture != null
          ? { id: decodeId(values?.search?.venture.id).id, slug: values?.search?.venture.slug }
          : undefined,
    });
    trackSearch(
      `${values?.search?.district != null ? `${values?.search?.district}, ` : ''}${
        values?.search?.city.name
      } - ${values?.search?.city.state.code}`
    );
    trackGSearch(
      `${values?.search?.district != null ? `${values?.search?.district}, ` : ''}${
        values?.search?.city.name
      } - ${values?.search?.city.state.code}`
    );
    router.push(url, asPath);
  };

  const topSearch = React.useMemo(() => {
    return props.searchTop != null
      ? Object.keys(props.searchTop).map((type, idx) => (
          <Grid item sm={4} xs={12} key={idx}>
            <Typography variant="h5" component="h3">
              {t(`home:most_searched.${type}`)}
            </Typography>
            <List dense disablePadding>
              {(props.searchTop! as any)[type].slice(0, 6).map((it: any, i: number) => (
                <ListItem key={i} disableGutters>
                  <RealestateTopSearchLink t={t} type={type} {...it} />
                </ListItem>
              ))}
            </List>
            <Collapse in={collapseTopSearchsOpen} timeout="auto">
              <List dense disablePadding>
                {(props.searchTop! as any)[type].slice(6).map((it: any, i: number) => (
                  <ListItem key={i} disableGutters>
                    <RealestateTopSearchLink t={t} type={type} {...it} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Button
              variant="text"
              color="inherit"
              onClick={() => {
                setCollapseTopSearchsOpen(!collapseTopSearchsOpen);
              }}
            >
              {t(`home:most_searched.see_${collapseTopSearchsOpen ? 'less' : 'more'}`)}
            </Button>
          </Grid>
        ))
      : null;
  }, [props.searchTop, collapseTopSearchsOpen]);

  return (
    <Layout {...props}>
      <div className="home-page">
        <div className="search">
          <OptimizedImage
            src={require('@app/public/images/home_banner.jpg?resize&sizes[]=400&sizes[]=640&size[]=760&sizes[]=960&size[]=1366&size[]=1600&size[]=1920')}
            alt="View a city"
            resize
            ext="jpg"
            className="search-bg"
          />
          <Container fixed>
            <div className="search-box">
              <div className="search-form">
                <Typography variant="h3" component="h1">
                  {t('home:search.title')}
                </Typography>
                <Box mt={3}>
                  <Form
                    onSubmit={searchRealestate}
                    initialValues={{ type: 'sale', realestateType: 'all' }}
                    render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="text-left">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              name="type"
                              render={({ input }) => (
                                <SelectField
                                  id="search-what"
                                  name={input.name}
                                  label={t`realestate-search:what`}
                                  fullWidth
                                  native
                                  onChange={input.onChange}
                                  value={input.value}
                                  options={[
                                    { value: 'sale', label: t`realestate:isfor.buy` },
                                    { value: 'rent', label: t`realestate:isfor.rent` },
                                  ]}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RealestateCategory t={t} />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              name="search"
                              render={({ input }) => (
                                <RealestateSearch
                                  t={t}
                                  value={input.value}
                                  onChange={input.onChange}
                                  location={
                                    longitude != null && latitude != null
                                      ? [longitude, latitude]
                                      : undefined
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="large"
                              fullWidth
                            >
                              {t('home:search.send')}
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  />
                </Box>
              </div>
              <div className="search-info">
                <Typography variant="h2">{t('home:search.info.title')}</Typography>
                <Typography variant="h6">{t('home:search.info.p1')}</Typography>
                <Typography variant="h6">{t('home:search.info.p2')}</Typography>
                <Box mt={2}>
                  <Typography variant="h6">{t('home:search.info.p3')}</Typography>
                </Box>
              </div>
            </div>
          </Container>
        </div>
        <section className="advertise">
          <Container fixed>
            <div className="advertise-left">
              <OptimizedImage
                src={require('@app/public/images/man-bg.jpg?sizes[]=320&sizes[]=600&sizes[]=900')}
                alt="A man tying its suit"
                resize
                ext="jpg"
                lazy
                lazyHeight={450}
              />
            </div>
            <div className="advertise-right">
              <Box my={4}>
                <Typography
                  variant="h1"
                  color="primary"
                  component="h2"
                >{t`home:sections.advertise_title`}</Typography>
              </Box>
              <Typography
                variant="h6"
                component="h3"
              >{t`home:sections.advertise_subtitle`}</Typography>
              <Box className="advertise-columns" my={5}>
                <div>
                  <OptimizedImage
                    src={require('@app/public/images/delivering-house.png')}
                    srcWebp={require('@app/public/images/delivering-house.png?webp')}
                    alt="A hand holding a house"
                    ext="png"
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                  >{t`home:advertise_columns.house`}</Typography>
                </div>
                <div>
                  <OptimizedImage
                    src={require('@app/public/images/customers.png')}
                    srcWebp={require('@app/public/images/customers.png?webp')}
                    alt="A magnifing glass with people inside"
                    ext="png"
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                  >{t`home:advertise_columns.customers`}</Typography>
                </div>
                <div>
                  <OptimizedImage
                    src={require('@app/public/images/realestate-intelligence.png')}
                    srcWebp={require('@app/public/images/realestate-intelligence.png?webp')}
                    alt="A graph with a arrow shooting up"
                    ext="png"
                  />
                  <Typography
                    variant="h6"
                    component="h3"
                  >{t`home:advertise_columns.statistics`}</Typography>
                </div>
              </Box>
              <a target="_blank" style={{ textDecoration: 'none' }} href={announceButtonHref}>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                >{t`home:advertise_label`}</Button>
              </a>
            </div>
          </Container>
        </section>
        <section className="most-searched contrast">
          <Container fixed>
            <Box mb={4}>
              <Typography variant="h4" component="h2">
                {t`home:most_searched.title`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {topSearch}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  );
};

Home.getInitialProps = async ({ apolloClient }): Promise<ApolloProps> => {
  let searchTop;
  try {
    const res = await apolloClient.query<SearchTopResultsQuery, SearchTopResultsQueryVariables>({
      query: SearchTopResultsDocument,
      variables: { first: 15 },
    });
    searchTop = res.data;
  } catch (e) {
    console.error(e);
  }

  return {
    searchTop,
    namespacesRequired: ['home', 'common', 'realestate-search', 'realestate', 'realty-listing'],
  };
};

export default i18n.withTranslation([
  'home',
  'common',
  'realestate-search',
  'realestate',
  'realty-listing',
])(withApollo(Home));
