import { ParsedUrlQuery } from 'querystring';
import i18next from '../../../i18n';
import { encodeCursor } from './relay';
import { LISTING_PER_PAGE } from '../../lib/constants/realestate';
import { urlBuilder } from './url';
import { TFunction } from 'next-i18next';
import { PublicUserType } from '../../components/graphql/types';
import { RealtyType, LocationType } from '../../components/graphql-hooks';

export interface ListingFilterVariables {
  state?: string;
  city?: string;
  district?: string;
  districtList?: string | string[];
  category?: string;
  isForSale?: boolean;
  isForRent?: boolean;
  isForExchange?: boolean;
  isForVacation?: boolean;
  street?: string;
  bedrooms?: number;
  garages?: number;
  bathrooms?: number;
  suites?: number;
  salePriceMin?: number;
  salePriceMax?: number;
  rentPriceMin?: number;
  rentPriceMax?: number;
  condominiumPriceMin?: number;
  condominiumPriceMax?: number;
  iptuPriceMin?: number;
  iptuPriceMax?: number;
  areaMin?: number;
  areaMax?: number;
  first?: number;
  cursor?: string;
  orderby?: string[];
  geosort?: string[];
  user?: string;
  forPartnership?: boolean;
}

interface ParsedListing {
  variables: ListingFilterVariables;
  type: string;
  page: number;
  isPartnership: boolean;
}

interface ParsedListingLocation {
  city?: string;
  state: string;
}

interface DisplayPreference {
  type: 'sale' | 'rent';
  price: number;
  filter: ListingFilterVariables;
}

export interface RealtyListingCardTitleOptions {
  t: TFunction;
  realty: RealtyType;
  context?: any;
}

export const optionalParamList = [
  'areaMin',
  'areaMax',
  'rentPriceMin',
  'rentPriceMax',
  'salePriceMin',
  'salePriceMax',
  'condominiumPriceMin',
  'condominiumPriceMax',
  'bedrooms',
  'bathrooms',
  'garages',
  'suites',
  'districtList',
];

export const filterArrays = ['rentPrice', 'salePrice', 'condominiumPrice', 'area'];

export const getDisplayPreference = (ops: {
  realty?: RealtyType;
  context?: any;
}): DisplayPreference => {
  let type = 'sale';
  switch (true) {
    case ops.context != null && ops.context.isForSale:
      break;
    case ops.context != null && ops.context.isForRent:
      type = 'rent';
      break;
    case ops.realty != null && ops.realty.isForSale && ops.realty.salePrice != null:
      break;
    case ops.realty != null && ops.realty.isForRent && ops.realty.rentPrice != null:
      type = 'rent';
      break;
  }

  let filter = {};
  let price = 0;
  if (type === 'sale') {
    filter = { isForSale: true };
    price = ops.realty?.salePrice || 0;
  } else if (type === 'rent') {
    filter = { isForRent: true };
    price = ops.realty?.rentPrice || 0;
  } else {
    throw new Error('unknown type ' + type);
  }

  return {
    type,
    filter,
    price,
  };
};

export const getOrderby = (type: string, orderby: string): string[] => {
  if (orderby === 'most_expensive' || orderby === 'least_expensive') {
    switch (type) {
      case 'sale':
        return orderby === 'most_expensive' ? ['-sale_price'] : ['sale_price'];
      case 'rent':
        return orderby === 'most_expensive' ? ['-rent_price'] : ['rent_price'];
      case 'vacation':
        return orderby === 'most_expensive' ? ['-vacation_price'] : ['vacation_price'];
      default:
        return orderby === 'most_expensive'
          ? ['-sale_price', '-rent_price', '-vacation_price']
          : ['sale_price', 'rent_price', 'vacation_price'];
    }
  }
  switch (orderby) {
    case 'most_recent':
      return ['-updated_at'];
    case 'largest_area':
      return ['-area'];
    case 'smallest_area':
      return ['area'];
    default:
      // FIXME: Colocar order by decente
      return ['hot'];
  }
};

export const parseListingLocation = (location: string): ParsedListingLocation => {
  const keys = ['city', 'state'];
  const parts = location.split('_');
  const locObj: any = {};
  while (parts.length > 0) {
    const it = parts.pop();
    const key = keys.pop();
    locObj[key!] = it;
  }
  return locObj;
};

export const formatListingLocation = (options: ParsedListingLocation) =>
  [options.city, options.state.toLocaleLowerCase()].filter(it => it != null).join('_');

export const parseListingQueryAndPath = (query: ParsedUrlQuery, asPath: string): ParsedListing => {
  const type = asPath.includes('alugar') ? 'rent' : 'sale';
  const isPartnership = asPath.includes('lancamento');

  // Deals with orderby
  const orderby = getOrderby(type, typeof query.orderby === 'string' ? query.orderby : 'relevant');

  // Deals with pagination
  const page: number = parseInt((query.page as string) || '1', 10);
  const cursor = page > 1 ? encodeCursor(LISTING_PER_PAGE * (page - 1) - 1) : undefined;

  // Deals with category
  let category = typeof query.category === 'string' ? query.category : undefined;
  if (i18next.i18n.exists(`realty-listing:inverse_categories_slug.${category}`)) {
    category = i18next.i18n.t(`realty-listing:inverse_categories_slug.${category}`);
  }
  category = category === 'all' ? undefined : category;

  // If the category doesn't exist set it as undefined. It would only produce
  // an error at the backend sending something that its enum doesn't expect
  let invalidCategory = false;
  if (category != null && !i18next.i18n.exists(`realty-listing:categories_slug.${category}`)) {
    category = undefined;
    invalidCategory = true;
  }

  // Deals with location
  const location =
    typeof query.location === 'string' ? parseListingLocation(query.location) : undefined;

  // Initializes Variables
  const variables: ListingFilterVariables = {
    first: LISTING_PER_PAGE,
    cursor,
    orderby,
    category,
    state: location?.state.toLocaleUpperCase(),
    city: location?.city,
    districtList:
      typeof query.districtList === 'string' ? query.districtList.split(',') : undefined,
    forPartnership: isPartnership,
    // Force those to false when the category is not valid to make sure
    // no results will return from it
    isForRent: invalidCategory ? false : asPath.includes('alugar') ? true : undefined,
    isForSale: invalidCategory ? false : asPath.includes('venda') ? true : undefined,
    isForVacation: invalidCategory ? false : undefined,
    isForExchange: invalidCategory ? false : undefined,
  };

  if (variables.districtList && variables.districtList.includes(''))
    variables.districtList = undefined;

  // Deals with optional filters
  optionalParamList.forEach(it => {
    if (query.hasOwnProperty(it) && query[it] != null) {
      if (filterArrays.includes(it)) {
        const value = (query[it] as string).split(',');
        (variables as any)[`${it}Min`] = parseInt(value[0], 10);
        (variables as any)[`${it}Max`] = parseInt(value[1], 10);
      } else {
        if (it !== 'districtList') (variables as any)[it] = parseInt(query[it] as string, 10);
      }
    }
    const itMin = `${it}Min`;
    if (query.hasOwnProperty(itMin) && query[itMin] != null) {
      (variables as any)[itMin] = parseInt(query[itMin] as string, 10);
    }
    const itMax = `${it}Max`;
    if (query.hasOwnProperty(itMax) && query[itMax] != null) {
      (variables as any)[itMax] = parseInt(query[itMax] as string, 10);
    }
  });

  return { variables, type, page, isPartnership };
};

export const getRealtyListingAsPath = (options: {
  t: TFunction;
  filter: ListingFilterVariables;
  partnership: boolean;
  includePage: boolean;
  page?: string;
  user?: string;
  orderby?: string;
}) => {
  const p = getDisplayPreference({ context: options.filter });
  const type = {
    sale: 'venda',
    rent: 'alugar',
  }[p.type];
  const c = options?.user != null ? undefined : options.filter.category || 'all';
  options.filter.category = i18next.i18n.exists(`realty-listing:categories_slug.${c}`)
    ? options.t(`realty-listing:categories_slug.${c}`)
    : c;
  const init =
    options.user != null
      ? `/${options.partnership ? 'incorporadora' : 'anunciante'}/${options.user}`
      : options.partnership
      ? '/lancamento'
      : `/${type}`;

  const parts = [
    options.filter.category,
    formatListingLocation({
      state: options.filter.state || '',
      city: options.filter.city,
    }),
  ];

  const params = optionalParamList.reduce((acc: any, it) => {
    acc[it] = (options.filter as any)[it];
    return acc;
  }, {});
  if (options.includePage && options.page != null) {
    params.page = options.page;
  }
  if (options.orderby) {
    params.orderby = options.orderby;
  }

  return urlBuilder(init, {
    parts,
    params,
  });
};

// TODO: Remove venture logic. Prefer getVentureUrl
export const getRealtyListingUrl = (options: {
  filter?: ListingFilterVariables;
  partnership?: boolean;
  includePage?: boolean;
  page?: string;
  user?: string;
  orderby?: string;
}) => {
  return urlBuilder(options.user != null ? '/user-listing' : '/realty-listing', {
    params: {
      page_from: 'search',
      ...options.filter,
      location:
        options.filter?.state != null
          ? formatListingLocation({
              state: options.filter.state,
              city: options.filter.city,
            })
          : undefined,
      page: options.includePage ? options.page : undefined,
      orderby: options.orderby,
      partnership: options.partnership,
      advertiser: options.user,
    },
  });
};

export const searchRealtyUrls = (options: {
  t: TFunction;
  filter: ListingFilterVariables;
  partnership: boolean;
  includePage: boolean;
  page?: string;
  user?: string;
  orderby?: string;
  venture?: { slug: string; id: string };
}): { url: string; asPath: string } => {
  if (options?.venture != null) {
    const vOpts = {
      ...options,
      id: options.venture.id,
      slug: options.venture.slug,
    };
    const url = getVentureUrl(vOpts);
    const asPath = getVentureAsPath(vOpts);
    return { url, asPath };
  } else {
    const url = getRealtyListingUrl(options);
    const asPath = getRealtyListingAsPath(options);
    return { url, asPath };
  }
};

export const getRealtyListingTitle = (options: {
  t: TFunction;
  user?: PublicUserType | undefined | null;
  category?: string;
  type?: string;
  state?: string;
  city?: string;
  district?: string | null;
  count: number;
  isSEO?: boolean;
  page?: number;
}) => {
  const category = options.t(
    `realestate:item_title.categories.${options.category != null ? options.category : 'all'}`,
    { count: options.count || 0 }
  );
  if (options.user != null) {
    return `${category} ${options.t`realestate:item_title.from_advertiser`} ${
      options.user.advertisingName
    }`;
  }
  const city = options.city != null ? options.city + ' - ' : '';
  const district = options.district != null ? options.district + ', ' : '';
  const preposition = options.t`realestate:item_title.preposition`;
  const location =
    options.state != null ? ` ${preposition} ${district}${city}${options.state}` : '';
  return `${options.isSEO ? options.count + ' ' : ''}${category} ${
    options.type != null ? options.t(`realestate:item_title.type.${options.type}`) : ''
  }${location}${
    options.isSEO && options.page != null && options.page > 1 ? ' · Página ' + options.page : ''
  }`;
};

export const countFilters = (filters: ListingFilterVariables) =>
  ['bathrooms', 'bedrooms', 'garages', 'suites'].filter(
    (it: any) => (filters as any)[it] != null && parseInt((filters as any)[it], 10) > 0
  ).length +
  ['area', 'condominium', 'rentPrice', 'salePrice', 'vacationPrice'].filter(
    (it: any) => (filters as any)[it + 'Min'] != null && (filters as any)[it + 'Max'] != null
  ).length;

export const getRealtyBreadcrumbAsPath = (
  partnership: boolean,
  t: TFunction,
  type: string,
  category?: string,
  state?: string,
  city?: string
) =>
  getRealtyListingAsPath({
    filter: {
      isForRent: type === 'rent' ? true : undefined,
      isForSale: type === 'sale' ? true : undefined,
      state,
      city,
      category,
    },
    partnership,
    t,
    includePage: false,
  });

export const getRealtyBreadcrumbUrl = (
  partnership: boolean,
  type: string,
  category?: string,
  state?: string,
  city?: string
) =>
  getRealtyListingUrl({
    filter: {
      isForRent: type === 'rent' ? true : undefined,
      isForSale: type === 'sale' ? true : undefined,
      state,
      city,
      category,
    },
    partnership,
    includePage: false,
  });

export const getRealtyListingCardTitle = (options: RealtyListingCardTitleOptions) => {
  // Common
  const preposition = options.t`realestate:item_title.preposition`;
  const of = options.t`realestate:item_title.of`;
  const tWith = options.t`realestate:item_title.with`;
  const and = options.t`realestate:item_title.and`;

  // Category
  const cat = options.t(`realestate:categories.${options.realty.category.toLocaleLowerCase()}`);

  // Type
  const p = getDisplayPreference(options);
  const type = p.type;
  const forType = options.t(`realestate:item_title.type.${type}`);

  // Location
  const showDistrict = options.context?.district == null;
  let location =
    options.realty.venture != null
      ? options.realty.venture.name
      : showDistrict
      ? options.realty.commercialDistrict || options.realty.location.district
      : undefined;
  location = location != null ? ` ${preposition} ${location}` : '';

  // Area
  const area = `${options.realty.area || 0}m²`;

  // Caracteristics
  const cs = getRealtyCaracteristicList({ ...options });
  const csLen = cs.length - 1;
  const csAnd = cs.length > 1 ? ' ' + and : '';
  const caracteristics = ` ${tWith} ${cs.slice(0, csLen).join(', ')}${csAnd} ${cs[csLen]}`;

  // Title
  return `${cat} ${forType}${location} ${of} ${area}${cs.length > 0 ? caracteristics : ''}`;
};

export const getRealtyListingCardLocation = (ops: {
  location?: LocationType;
  comercialDistrict?: string | null;
}) =>
  ops.location != null
    ? `${ops.location.district || ops.comercialDistrict}, ${ops.location.city.name} - ${
        ops.location.city.state.code
      }`
    : '';

export const getRealtyCaracteristicList = (options: {
  t: TFunction;
  realty: RealtyType | null | undefined;
}) => {
  const catType = getCategoryType(options.realty?.category);
  const cv = [
    options.realty?.bedrooms,
    options.realty?.bathrooms,
    options.realty?.suites,
    options.realty?.garages,
  ];
  return [`bedrooms${catType != null ? '_' + catType : ''}`, 'bathrooms', 'suites', 'garages']
    .map((it, i) =>
      cv[i] != null && cv[i]! > 0
        ? `${cv[i]} ${options.t(`realestate:caracteristics.${it}`, { count: cv[i]! })}`
        : undefined
    )
    .filter(it => it != null);
};

export const getCategoryType = (category?: string) => {
  if (category != null) {
    const categoryParts = category.split('_');
    return categoryParts.length > 0 ? categoryParts[0] : null;
  }
  return null;
};

export const getRealtyPrice = (ops: { realty: RealtyType; context?: any }): number => {
  const p = getDisplayPreference(ops);
  return p.price;
};

export const getVentureUrl = (options: {
  filter?: ListingFilterVariables;
  slug: string;
  id: string;
  page?: string;
  includePage?: boolean;
  orderby?: string;
  type?: string;
}): string => {
  return urlBuilder('/venture-detail', {
    params: {
      page_from: 'search',
      ...options.filter,
      type: options?.type,
      page: options.includePage ? options.page : undefined,
      orderby: options.orderby,
      uid: options.id,
      slug: options.slug,
    },
  });
};

export const getVentureAsPath = (options: {
  t?: TFunction;
  filter?: ListingFilterVariables;
  slug: string;
  id: string;
  page?: string;
  includePage?: boolean;
  orderby?: string;
  type?: string;
}): string => {
  const { t } = options;
  const init = `/condominio/${options.slug}/${options.id}`;

  const parts = [];
  // FIXME: We should enforce t here
  if (options?.type != null && t != null) {
    parts.push(t(`realestate:isfor.${options.type}`).toLocaleLowerCase());
  }

  const params = optionalParamList.reduce((acc: any, it) => {
    if (options?.filter?.hasOwnProperty(it)) {
      acc[it] = (options.filter as any)[it];
    }
    return acc;
  }, {});
  if (options.includePage && options.page != null) {
    params.page = options.page;
  }
  if (options.orderby) {
    params.orderby = options.orderby;
  }

  return urlBuilder(init, {
    parts,
    params,
  });
};

export const getVentureLink = (slug: string, id: string) => ({
  as: getVentureAsPath({ slug, id }),
  href: getVentureUrl({ slug, id }),
});
