import * as React from 'react';
import { TFunction } from 'next-i18next';
import { Autocomplete } from '@material-ui/lab';
import { useSearchLazyQuery } from '@app/components/graphql-hooks';
import { debounce } from 'ts-debounce';
import { TextField } from '@material-ui/core';

interface OwnProps {
  t: TFunction;
  label?: string | undefined;
  location?: string[] | undefined;
  onChange(value: any): void;
  value: any | undefined;
  searchKind?: undefined | string;
  className?: string;
  name?: string;
  citySlug?: string;
  stateSlug?: string;
}

const realestateSeach = (realty: any): string => {
  switch (realty.kind) {
    case 'CITY':
      return `${realty.city.name} - ${realty.city.state.code}`;
    case 'DISTRICT':
      return `${realty.district}, ${realty.city.name} - ${realty.city.state.code}`;
    case 'STREET':
      return `${realty.street}, ${realty.city.name} - ${realty.state.code}`;
    case 'VENTURE':
      return `${realty.venture.name}, ${realty.district}, ${realty.city.name} - ${realty.city.state.code}`;
    default:
      return `${realty.city.state.code}`;
  }
};

const kindOrder = ['CITY', 'DISTRICT', 'STREET', 'VENTURE'];
const mapOptions = (data: any, t: TFunction): any[] => {
  const count: any = {
    CITY: 0,
    DISTRICT: 0,
    STREET: 0,
    VENTURE: 0,
  };
  return (data?.search?.edges || [])
    .map((it: any) => ({
      ...it.node,
      kindLabel: t(`realestate:kind.${it.node.kind}`),
    }))
    .filter((it: any) => {
      count[it.kind] = count[it.kind] + 1;
      return count[it.kind] <= 5;
    })
    .sort((a: any, b: any) => {
      const ia = kindOrder.indexOf(a.kind);
      const ib = kindOrder.indexOf(b.kind);
      if (ia > ib) {
        return 1;
      } else if (ia < ib) {
        return -1;
      }
      return 0;
    });
};

export const RealestateSearch: React.FunctionComponent<OwnProps> = ({
  t,
  label = t`realestate-search:where`,
  onChange,
  location,
  value = null,
  searchKind = '',
  className = '',
  name,
  stateSlug,
  citySlug,
}) => {
  const [search, query] = useSearchLazyQuery();
  const [options, setOptions] = React.useState<any[]>([]);
  const [open, setOpen] = React.useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '') {
      setOptions([]);
      setOpen(false);
    } else {
      fetch(inputValue, location, searchKind, stateSlug, citySlug);
      setOpen(true);
    }
  };

  const fetch = React.useMemo(
    () =>
      debounce((q: string, geosort: any, kind: string, stateSlug?: string, citySlug?: string) => {
        try {
          search({ variables: { q, geosort, kind, stateSlug, citySlug } });
          // query?.refetch({ q: input, geosort });
        } catch (e) {
          console.error(e);
        }
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;
    if (active) {
      setOptions(mapOptions(query.data || [], t));
    }
    return () => {
      active = false;
    };
  }, [query.data]);

  const handleChange = async (e: any, newValue: any, reason: string) => {
    onChange(newValue);
    if (reason === 'clear') {
      setOpen(false);
    }
  };

  const handleClose = (e: any, reason: string) => {
    if (reason !== 'blur') {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Autocomplete
      id={`autocomplete-${label}`}
      clearText={t('common:clear')}
      closeText={t('common:close')}
      loadingText={t('common:loading')}
      noOptionsText={t('common:no-options')}
      openText={t('common:open')}
      className={className}
      autoSelect
      autoHighlight
      open={open}
      clearOnBlur={false}
      getOptionLabel={(op: any) => (op?.kind != null ? realestateSeach(op) : '')}
      groupBy={searchKind === '' ? op => op.kindLabel : undefined}
      getOptionSelected={(op, it) => op.id === it.id}
      options={options}
      loading={query.loading}
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
      openOnFocus
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          onChange={handleInputChange}
          label={label}
          name={name}
          variant="outlined"
        />
      )}
    />
  );
};
