import * as React from 'react';
import { TFunction } from 'next-i18next';
import { Link } from '../../../i18n';
import { getRealtyListingUrl, getRealtyListingAsPath } from '@app/util/realty-listing';

interface OwnProps {
  t: TFunction;
  type: 'sale' | 'rent' | 'region';
  city: string;
  citySlug: string;
  state: string;
  stateCode: string;
  category?: string;
}

export const RealestateTopSearchLink: React.FunctionComponent<OwnProps> = ({ t, ...props }) => {
  const ops = React.useMemo(
    () => ({
      label:
        props.type === 'region'
          ? `${props.city} (${props.state})`
          : `${t(`realestate:categories.${props.category!.toLocaleLowerCase()}`, {
              count: 2,
            })} ${t(
              `realestate:item_title.type.${props.type}`
            )} ${t`realestate:item_title.preposition`} ${props.city}`,
      href: getRealtyListingUrl({
        filter: {
          ...props,
          city: props.citySlug,
          state: props.stateCode,
          category: props?.category ?? undefined,
          isForSale: props.type === 'sale' || props.type === 'region' ? true : undefined,
          isForRent: props.type === 'rent' ? true : undefined,
        },
      }),
      as: getRealtyListingAsPath({
        t,
        filter: {
          ...props,
          city: props.citySlug,
          state: props.stateCode,
          category: props?.category ?? undefined,
          isForSale: props.type === 'sale' || props.type === 'region' ? true : undefined,
          isForRent: props.type === 'rent' ? true : undefined,
        },
        partnership: false,
        includePage: false,
      }),
    }),
    [props]
  );
  return (
    <Link href={ops.href} as={ops.as} passHref>
      <a style={{ textDecoration: 'none' }}>{ops.label}</a>
    </Link>
  );
};
