export const realestateCategories = [
  'all',
  'c_building',
  'c_clinic',
  'c_farm-grange',
  'c_hangar-deposit-warehouse',
  'c_lot-land',
  'c_realty',
  'c_room',
  'c_store',
  'r_apartment',
  'r_condominium-house',
  'r_condominium-land',
  'r_country-house-villa',
  'r_flat',
  'r_house',
  'r_kitnet',
  'r_loft',
  'r_land',
  'v_apartment',
  'v_house',
  'r_penthouse',
];

export const comercialCategories = [
  'c_building',
  'c_clinic',
  'c_hangar-deposit-warehouse',
  'c_lot-land',
  'c_realty',
  'c_room',
  'c_store',
];

export const residentialCategories = [
  'r_apartment',
  'r_penthouse',
  'r_condominium-house',
  'r_condominium-land',
  'r_country-house-villa',
  'r_flat',
  'r_house',
  'r_kitnet',
  'r_loft',
  'r_land',
  'c_farm-grange',
];

export const vacationCategories = ['v_apartment', 'v_house'];
