import * as React from 'react';
import { comercialCategories, residentialCategories } from '@app/data/realestate_categories';
import { SelectField, SelectOption } from '@app/components/form/select-field';
import { TFunction } from 'next-i18next';
import { Field } from 'react-final-form';

interface OwnProps {
  t: TFunction;
  label?: string | undefined;
  name?: string;
  includeAll?: boolean;
  required?: boolean;
  native?: boolean;
  onChange?: (value: any) => void;
}

export const RealestateCategory: React.FunctionComponent<OwnProps> = ({
  t,
  label = t`realestate-search:realty_type`,
  name = 'realestateType',
  includeAll = true,
  onChange,
  native,
}) => {
  const categoryMapper = (it: any) => ({
    value: it,
    label: t(`realestate:categories.${it}`),
    group: false,
  });

  let base: SelectOption[] = [];
  if (includeAll) {
    base = native
      ? [
          {
            group: true,
            label: t`realestate:category_types.options`,
            value: '',
            options: [categoryMapper('all')],
          },
        ]
      : [
          { group: true, label: t`realestate:category_types.options`, value: '' },
          categoryMapper('all'),
        ];
  }

  const reCategories = native
    ? base.concat([
        {
          group: true,
          label: t`realestate:category_types.residential`,
          value: '',
          options: residentialCategories.map(categoryMapper),
        },
        {
          group: true,
          label: t`realestate:category_types.comercial`,
          value: '',
          options: comercialCategories.map(categoryMapper),
        },
      ])
    : base
        .concat([{ group: true, label: t`realestate:category_types.residential`, value: '' }])
        .concat(residentialCategories.map(categoryMapper))
        .concat([{ group: true, label: t`realestate:category_types.comercial`, value: '' }])
        .concat(comercialCategories.map(categoryMapper));

  return (
    <Field
      name={name}
      type="select"
      // validate={value => {
      //   if (required && !value) {
      //     return t`common:form.this-is-required`;
      //   }
      // }}
      render={({ input, meta }: any) => (
        <SelectField
          name={input.name}
          label={label!}
          value={input.value}
          native
          onChange={e => {
            // Do not select "nothing" when the user clicks on a group title
            if (e.target.value === undefined) {
              return;
            }

            input.onChange(e.target.value);
            if (onChange != null) {
              onChange(e.target.value);
            }
          }}
          options={reCategories}
          error={meta.error && meta.touched ? meta.error : null}
        />
      )}
    />
  );
};
